export default {
  sdk: "https://wallet.getmash.com/sdk/sdk.js",
  earnerID: "d6a20082-ab85-4376-8ebc-cb31e5f4ca4e",
  resourceID: "827e43ed-68fd-4989-a179-9332015d1f1e",
  boosts: {
    icon: "fire",
    variant: "colorized",
    layoutMode: "inline",
    floatLocationDesktop: "bottom-left",
    floatLocationMobile: "bottom-left",
  },
};


